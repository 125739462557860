
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        

























.hardware-counter,
[class*='hardware-counter--'] {
  position: relative;
  z-index: 5;
  padding: 0;
  line-height: 1;

  ::v-deep strong {
    /* stylelint-disable-next-line declaration-no-important */
    font-weight: 900 !important;
  }

  [class*='label'] {
    position: relative;
    z-index: 1;
    padding: 0.75rem 1.5rem 1rem;
    font-family: $ff-alt;
    font-size: 1rem;
    font-weight: 900;
    white-space: nowrap;
    border-radius: 5px;

    @include mq(xs) {
      font-size: 1.1rem;
    }

    @include mq(s) {
      font-size: 1.4rem;
    }

    @include mq(m) {
      font-size: 1.6rem;
    }

    @include mq(l) {
      font-size: 2rem;
    }
  }
}

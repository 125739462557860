
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        

























































































::v-deep {
  .product-card {
    height: 100%;

    @include mq('l') {
      width: 30rem;
    }
  }

  .product-card__inner {
    &:not(.offers-packs__rows &) {
      min-height: 36.5rem;
    }
  }
}

// ⚠️ Custom styling for product cards carousels on OfferPacks template ("offres & packs").
// Enable wrapping to effectively disable carousel behaviour and let cards span on as many rows as necessary.
// Disable mask gradient.
// Add extra padding to avoid sticky offer to overlap with content.
// Reset overflow to avoid clipping shadows.
.pack-carousel {
  &.offers-packs__rows__item {
    ::v-deep .carousel__scroller {
      @include mq('l') {
        --slide-gap: 4rem 3rem;

        flex-wrap: wrap;
        justify-content: flex-start;
        mask-image: none;
        overflow: initial;
      }

      @include mq('xxl') {
        --slide-gap: 5rem;
      }
    }

    @include mq($from: l) {
      padding-right: 0;
    }

    @include mq($from: xl, $until: xxl) {
      padding-right: 0;
    }
  }

  &.offers-packs__rows__item .product-card {
    width: 28rem;
  }

  &.selection__products__figure__icon {
    height: 2.5rem;
  }
}
